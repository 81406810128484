//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import whiteBox from '~/components/common/white-box'
export default {
  components: {
    whiteBox
  },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Fotótás'
    },
    btnText: {
      type: String,
      default: 'Tovább'
    },
    btnUrl: {
      type: String,
      default: '#'
    }
  },
  data () {
    return {

    }
  }
}
