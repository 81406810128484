//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    bgImageClass: {
      type: String,
      default: 'bg-fabric'
    },
    backgroundTitle: {
      type: String,
      default: 'Felcím'

    },
    backgroundId: {
      type: String,
      required: true

    },
    foregroundTitle: {
      type: String,
      default: 'Cím'

    },
    foregroundId: {
      type: String,
      required: true
    },
    backgroundImage: {
      type: String,
      default: '/img/default-background.jpg'
    },
    foregroundImage: {
      type: String,
      default: '/img/default-foreground.jpg'

    },
    imageInRight: {
      type: Boolean,
      default: false
    },
    btnText: {
      type: String,
      default: 'Tovább'
    },
    btnUrl: {
      type: String,
      default: '#'
    }
  },
  data () {
    return {

    }
  }
}
