//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import modal from '~/components/common/modal'

export default {
  components: {
    modal
  },
  props: {
    backgroundTitle: {
      type: String,
      default: 'Felcím'

    },
backgroundId: {
      type: String,
      required: true

    },
    foregroundTitle: {
      type: String,
      default: 'Cím'

    },
    foregroundId: {
      type: String,
      required: true
    },
    imagesArray: {
      type: Array,
      default: null
    },
    headerArray: {
      type: Array,
      default: null
    },
    btnUrl: {
      type: String,
      default: '/galeria'
    }
  },
  data () {
    return {
      slideIndex: 1,
      ismodal: false

    }
  },
  computed: {
    slideImages () {
      return this.imagesArray.filter((value, index) => {
        return index + 1 === this.slideIndex
      })
    }
  },
  methods: {
    escapeKeyListener (evt) {
      if (evt.keyCode === 27) {
        this.ismodal = false
      }
    },
    // Open the Modal
    openModal (id) {
      this.ismodal = true
      this.slideIndex = id
      document.addEventListener('keyup', this.escapeKeyListener)
    },

    // Close the Modal
    closeModal () {
      this.ismodal = false
      this.slideIndex = 1
      document.removeEventListener('keyup', this.escapeKeyListener)
    },
    // Next/previous controls
    plusSlides (n) {
      if (this.slideIndex + n > this.imagesArray.length) {
        this.slideIndex = 1
      } else if (this.slideIndex + n < 1) {
        this.slideIndex = this.imagesArray.length
      } else {
        this.slideIndex += n
      }
    }

  }
}
