//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImgSection from '~/components/pages/ImgSection'
import services from '~/components/pages/index/services'
import rooms from '~/components/pages/index/rooms'
import PhotoPromo from '~/components/pages/PhotoPromo'
import GallerySection from '~/components/pages/GallerySection'
import OneColumnSection from '~/components/pages/OneColumnSection'
import whiteBox from '~/components/common/white-box'
export default {
  components: {
    ImgSection,
    services,
    rooms,
    PhotoPromo,
    GallerySection,
    OneColumnSection,
    whiteBox
  },
  data () {
    return {
      active: false,
      currentPage: 0,
      heroContent: [

        {
          title: 'Degenfeld-Schomburg kastély',
          subtitle: 'Esküvő | Szervezés | Fotózás',
          url: '/img/index/degenfeld-fejlec.jpg',
          url2: '/img/index/degenfeld-fejlec-mobil2.jpg'
        }
      ],
      images: [
        {
          thumb: '/img/index/index-thumb-1.jpg',
          url: '/img/index/index-1.jpg'
        },
        {
          thumb: '/img/index/index-thumb-2.jpg',
          url: '/img/index/index-2.jpg'
        },
        {
          thumb: '/img/index/index-thumb-3.jpg',
          url: '/img/index/index-3.jpg'
        },
        {
          thumb: '/img/index/index-thumb-4.jpg',
          url: '/img/index/index-4.jpg'
        },
        {
          thumb: '/img/index/index-thumb-5.jpg',
          url: '/img/index/index-5.jpg'
        },
        {
          thumb: '/img/index/index-thumb-6.jpg',
          url: '/img/index/index-6.jpg'
        }
      ],
      galleryHeader: [
        {
          name: 'Összes fotó',
          value: 'all',
          url: '/galeria'
        },
        {
          name: 'Kastély',
          value: 'castle',
          url: '/galeria/?tema=castle'
        },
        {
          name: 'Esküvők',
          value: 'weddings',
          url: '/galeria/?tema=weddings'
        },
        {
          name: 'Rendezvények',
          value: 'events',
          url: '/galeria/?tema=events'
        }
      ]
    }
  },
  mounted () {
    // window.addEventListener('scroll', () => {
    //   // window.pageYOffset
    //   console.log(window.pageYOffset / (document.body.scrollHeight - window.innerHeight))
    //   document.body.style.setProperty('--scroll', window.scrollY / (document.body.scrollHeight - window.innerHeight))
    // }, false)
  },
  beforeMount () {
    window.addEventListener('resize', this.applyHeroContentChangeMethod)
    // window.addEventListener('scroll', this.handleScroll)
    setInterval(() => {
      if (this.currentPage === this.heroContent.length - 1) {
        this.currentPage = 0
      } else {
        this.currentPage++
      }
      this.onHeroContentChange()
    }, 5000)
  },
  destroyed () {
    // window.removeEventListener('scroll')
    window.removeEventListener('resize', this.applyHeroContentChangeMethod)
  },
  methods: {
    applyHeroContentChangeMethod () {
      this.heroContent.forEach((item, index) => {
        this.onHeroContentChange(index)
      })
    },
    onHeroContentChange (index) {
      /* if (this.$refs.split[index]) {
        const w = this.$refs.split[index].clientWidth // get the split element client viewportwidth
        const h = this.$refs.split[index].clientHeight // get the split element client viewportheight
        const imgW = this.$refs.splitImg[index].naturalWidth // get the img natural width
        const imgH = this.$refs.splitImg[index].naturalHeight // get the img natural height

        // calculate correction factor for image (if the image is longer then the screen is wide
        // or it is higher then it is tall or is it shorter then wide or lower then tall)
        let corr = 1
        if (w / imgW > h / imgH) {
          corr = w / imgW
        } else {
          corr = h / imgH
        }

        // calculate image offsets to remain in the center
        // eg: the image is wider then the screen, then we have to
        // start with a little offset to make it appear on the center
        const imgWDiff = (imgW * corr) - w
        const imgWOffset = imgWDiff / 2

        const imgHDiff = (imgH * corr) - h
        const imgHOffset = imgHDiff / 2

        // TODO 100 could be outsourced to a parameter
        // how many grids should be on each axis
        const gridCountX = Math.ceil(w / 100)
        const gridCountY = Math.ceil(h / 100)
        // how long should be a grid in px on the X and Y axios
        const gridWidthX = (w / gridCountX) / w * 100
        const gridWidthY = (h / gridCountY) / h * 100

        // get the image and the starting delay
        const img = this.heroContent[index].url
        const delay = 0.05

        // remove the child nodes (img tag or previous grid division)
        this.removeAllChildNodes(this.$refs.split[index])

        // calculate each slice of the image
        for (let x = 0; x < gridCountX; x++) {
          for (let y = 0; y < gridCountY; y++) {
          // calculate the top and left position of the current grid tile (in %)
            const top = 100 / gridCountY * y
            const left = 100 / gridCountX * x

            // calculate the background position with the offset to move the image to its right place
            const bgPosX = -imgWOffset - (w / gridCountX * x)
            const bgPosY = -imgHOffset - (h / gridCountY * y)

            // create a new div, we will apply the current part of the background to this div
            const div = document.createElement('div')

            // apply 0.01% correction to gridwidth and heigth to remove any errors
            // apply correction on the imgW and imgH to cover the screen size
            div.setAttribute('style', `
              position: absolute;
              top: ${top}%;
              left: ${left}%;
              width: ${gridWidthX + 0.01}%;
              height: ${gridWidthY + 0.01}%;
              background-image: url('${img}');
              background-size: ${imgW * corr}px ${imgH * corr}px;
              transition-delay: ${x * delay + y * delay}s;
              background-position: ${bgPosX}px ${bgPosY}px;
              background-repeat: no-repeat;
            `)
            this.$refs.split[index].appendChild(div)
          }
        }
      } */
    },
    removeAllChildNodes (parent) {
      while (parent.firstChild) {
        parent.removeChild(parent.firstChild)
      }
    }
  }
}
