//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    bgImageClass: {
      type: String,
      default: 'bg-contact'
    },
    backgroundTitle: {
      type: String,
      default: 'Felcím'

    },
backgroundId: {
      type: String,
      required: true

    },
    foregroundTitle: {
      type: String,
      default: 'Cím'

    },
    foregroundId: {
      type: String,
      required: true
    },
    btnText: {
      type: String,
      default: 'Tovább'
    },
    btnUrl: {
      type: String,
      default: '#'
    }
  }
}
